import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import PageHeader from "../../components/pageHeader"
import SEO from "../../components/seo"

import axios from 'axios'

import ScrollAnimation from 'react-animate-on-scroll';
import Blog1 from '../../components/images/blog2.js'
import ImageNatalie from '../../components/images/imageNatalie.js'


class DemarquezVous extends React.Component {

  constructor(props){
    super(props)
    this.state = {
        year: new Date().getFullYear(),
    }
  }

  componentDidMount() {
      this.setState({year: new Date().getFullYear()})
  }

    render(){
    return(
      <Layout>
        <SEO title="Démarquez-vous avec un CV qui a de l’impact! | Gestion Mieux-Être" />
        <Blog1 />
        <div className="main-wrapper2">
            <div className="tleft">
                <br /><br /><br /><br />
                <h2>Démarquez-vous avec un CV qui a de l’impact ! <small style={{'text-transform':'none'}}>(2e partie)</small></h2>
                <h3 style={{'text-transform':'none','color':'black'}}>Le cœur de votre CV : vos réalisations et succès</h3>
                <br /><br />
                <p>Vous êtes nombreux à vous interroger quant au contenu et à la longueur des CV en {this.state.year}.   Voici la ligne de conduite gagnante :  si vous êtes un professionnel ou gestionnaire aguerri comptant un 15 à 20 ans d’expérience, votre CV aura probablement 2 pages sur lesquelles vous allez exposer (en point de forme) 15 à 18 réalisations significatives.  Si votre carrière est plus récente, vous retiendrez probablement un maximum d’une dizaine points à traiter sur une seule page.</p>
                <p>Vous voulez que votre CV soit percutant, assurez-vous que plus de 50% des réalisations que vous y présentez aient eu lieu dans les 5 dernières années.  Vous conviendrez avec moi que les recruteurs portent un intérêt assez limité pour des mandats réalisés en 2001, il y a maintenant près de 20 ans!   </p>
                <p>Mais surtout, utilisez votre CV pour présenter vos succès et réalisations … et non pas une description mécanique des postes que vous avez occupés.  Je répète tellement fréquemment ce conseil !  Ne perdez pas d’espace à lister vos tâches qui ne convaincront personne quant à la qualité de votre candidature.  </p>
                <p>Un exemple classique à éviter : ne nous dites pas que vous avez géré le budget de votre unité. Mais oui, bien sûr que vous avez géré le budget… mais en quoi cet élément vous démarque des autres candidats?  </p>
                <p>Soyez convaincant en retenant des verbes d’action.  En {this.state.year}, dire au recruteur que vous avez « collaboré, participé, géré ou contribué … » n’est plus suffisant.  Démontrez plutôt que vous avez « amélioré, optimisé, augmenté … » en exposant ce dont vous êtes capable de faire.  Séduisez aussi les recruteurs en illustrant vos succès avec des éléments quantifiables (dollar, unité, %, ...).  </p>
                <p>Un dernier conseil :  présentez vos réalisations sans vous soucier de vous vanter.   Retenez que votre CV est là pour vous faire valoir.  Démontrez au recruteur comment vous êtes un joueur contributif en donnant des preuves de ce dont vous êtes capable. Vos succès en témoignent.  </p>
                <p>En {this.state.year}, démarquez-vous avec un CV qui a de l’impact! </p>
                <p><b>Au plaisir de vous croiser,</b></p>
                <p></p>
                <div className="owner-wrapper2">
                    <ImageNatalie />
                    <div className="owner-details">
                        <b>Natalie Bertrand</b><br />
                        M.Sc. – CRHA
                    </div>
                </div>
                <br /><br />
            </div>
        </div>
      </Layout>
    )
  }

}

export default DemarquezVous
